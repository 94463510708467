<template>
  <div class="wrapper">
    <Section>
      <HeadingBlock
        :data="{ blockTitle: 'Recuperar password' }"
        style="margin-bottom: 2.5rem"
      />

      <p v-show="this.user.erorMsg" class="errorSubmitPw">
        {{ this.user.erorMsg }}
      </p>

      <div class="form">
        <div v-for="field in getFormFields" :key="field.name">
          <input
            class="form__control"
            :type="field.type"
            :placeholder="field.placeholder"
            @change="updateValue($event, field.id == 0 ? true : false)"
          />
          <span v-show="field.errMsg">{{ field.errMsg }}</span>
        </div>
        <ButtonSubmit
          btnClass="primary-btn full-width-btn "
          btnLabel="Submeter"
          @submit="changePassword()"
        />
      </div>
    </Section>
  </div>
</template>
<script>
import Section from "@/components/Section/Section.vue";
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "RecoverPassword",
  components: {
    Section,
    ButtonSubmit,
    HeadingBlock,
  },
  data() {
    return {
      newPw: "",
      repeatPw: "",
      checkErrFields: false,
    };
  },
  mounted() {
    this.userName != undefined && this.$router.push("/");
    this.updateUserState({ ok: false });
  },
  beforeDestroy() {
    this.updateUserState({ erorMsg: false });
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      userName: "user/getUserName",
    }),
    getFormFields() {
      const checkErrFields = this.checkErrFields;
      const arr = [
        {
          id: 0,
          name: "newpw",
          type: "text",
          placeholder: "Nova Password",
          errMsg: checkErrFields
            ? this.newPw == ""
              ? "Campo obrigatório"
              : false
            : false,
        },
        {
          id: 1,
          name: "repeatPw",
          type: "text",
          placeholder: "Repetir nova Password",
          errMsg: checkErrFields
            ? this.repeatPw == ""
              ? "Campo obrigatório"
              : this.repeatPw !== this.newPw
              ? "Password não coincide"
              : false
            : false,
        },
      ];

      return arr;
    },
  },
  updated() {
    const pwUpdated = this.user.ok;
    pwUpdated && this.$router.push("/");
  },
  methods: {
    ...mapMutations({
      updateUserState: "user/UPDATE_STATE",
    }),
    updateValue(e, value) {
      let val = e.target.value;
      value ? (this.newPw = val) : (this.repeatPw = val);
    },
    changePassword() {
      this.checkErrFields = true;

      if (
        this.newPw !== this.repeatPw ||
        this.newPw == "" ||
        this.repeatPw == ""
      )
        return;

      let obj = this.$route.query;
      obj = Object.assign(obj, { password: this.newPw });

      this.$store.dispatch("user/updatePasswordRecover", obj);
    },
    __preventChars(e) {
      // @keypress -> allow only: A-Z a-z 0-9 _
      const charCode = e.charCode;
      const charCodeArr = [95];

      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 65 && charCode <= 90) ||
        (charCode >= 97 && charCode <= 122) ||
        charCodeArr.includes(charCode)
      )
        return true;
      e.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/Forms/Form.scss";
.form span {
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  color: $brandColor;
}

.errorSubmitPw {
  color: $brandColor;
  font-size: 0.875rem;
  margin: 0 auto 2rem;
}
</style>
